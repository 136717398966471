<template>
    <div class="card connection-comp">
        <h5 class="post-heading mb-4 mt-2">Wie komme ich hin?</h5>
        
        <form action="" class="connection">
            <div class="connection__item">
                <i class="material-icons" aria-hidden="true">gps_fixed</i>
                <input type="text"
                v-model="theStart"
                placeholder="Start" />
            </div>
            
            <div class="connection__item">
                <i class="material-icons" aria-hidden="true">room</i>
                <input type="text"
                :value="ziel"
                @input="ziel = $event.target.value" 
                placeholder="Ziel" />
            </div>
            
            <div class="connection__item btn-style">
                <div
                @click="openNahSH()"
                @keyup.enter="openNahSH()"
                tabindex="0"
                class="btn btn-hover mt-3 icon-before connection__item__content"
                title="Busverbindung suchen">
                Verbindung suchen
            </div>
        </div>
    </form>
</div>
</template>

<script>

export default {
    name: "CardConnection",
    props: {
        address: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            theStart: '',
            theTarget: '',
        }
    },
    computed: {
        ziel: {
            get: function () {
                return this.theTarget == "" ? this.address : this.theTarget;
            },
            set: function (newValue) {
                this.theTarget = newValue;
            }
        }
    },
    mounted() {
        this.ziel = this.address;
    },
    methods: {
        openNahSH() {
            var link = 'https://nah.sh.hafas.de/bin/query.exe/dn?url=%2Fhome%2FTimeTableForm';
            if (this.theStart != '') {
                link += "&S=" + this.theStart;
            }
            if (this.ziel != "") {
                link += "&Z=" + this.ziel;
            }
            window.open(link);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card{
    position: relative;
    border: none;
    @media(min-width:992px){
        margin-top: 40px;
    }
    
    &-title {
        margin-bottom: 1.5rem;
    }
    
    .centered {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
    }
    
    .connection {
        display: block;
        max-width: 600px;
        
        input[type="text"] {
            width: 100%;
            min-width: 200px;
            height: 40px;
            padding-left: 15px;
            
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }
        
        &__item {
            width: 100%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            margin-bottom: 10px;
            
            .material-icons {
                display: inline-block;
                align-self: center;
                width: 40px;
            }
            
            .btn {
                margin-left: 40px;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
    @media (max-width: 500px) {
        padding: 0 1rem;
        margin-left: -15px;
        margin-right: -15px;
        box-shadow: none;
    }
}

.btn-style {
    display: flex;
    justify-content: center;
}

</style>
